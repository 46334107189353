.itemDescription {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /* padding: 0 15% 0 1%; */
  color: var(--mainRoyalBlue);
  overflow: hidden;
  text-overflow: ellipsis;
}

.episodeDescription {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /* padding: 0 15% 0 1%; */
  color: var(--silver);
  overflow: hidden;
  text-overflow: ellipsis;
}