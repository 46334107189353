.BuyDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 2% 0 0 0;
  flex-grow: 1;
  margin-right: auto;
}

.title {
  margin: 2% 0;
  font-weight: bold;
}

.text {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2%;
}