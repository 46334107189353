.DownloadInstructionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  color: var(--brand);
  margin: 2% auto 5% auto;
  flex-grow: 1;
  max-height: 100%;
}

.before {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.after {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  justify-content: center !important;
}

.FaIcon {
  font-size: 28px;
  color: var(--brand);
}

.FaIcon:hover {
  color: var(--brandHover)
}
 
.FaArrow {
  font-size: 20px;
}

.maxContainer {
  max-height: 100%;
  min-height: 100vh;
  background-color: #12161d;
}

.downloadRowFiller {
  display: flex;
  visibility: hidden;
  flex-direction: row;
  align-items: center;
}

.downloadRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NextButton, .PreviousButton {
  color: black;
  margin: 0;
  padding: 2px 10px 0px 10px;
  background-color: var(--silver);
  font-weight: bold;
}

.NextButton:hover, .PreviousButton:hover {
  cursor: pointer;
  background-color: var(--brandHover);
}

.pageNumbers {
  margin: 0 10px 0 10px;
}

.pageArrows {
  display: flex;
  flex-direction: row;
  float: center;
}

.pageControls {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 5px;
  bottom: 5%;
  background: white;
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
}


