.voucherTitle {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.voucherButtons {
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.voucherButton {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.voucherButton:hover {
  border: 1px solid var(--brandHover);
  color: var(--brand);
  background-color: var(--brandHover);
}

.voucherApiError {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--red);
}
