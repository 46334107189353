.folderName {
  margin-right: 0.5rem;
  background: var(--folderNamesBackground);
  color: var(--folderNamesColor);
  border-radius: 5px;
  /*padding: 10px 10px;*/

  border-radius: 100px;
}
.folderName:hover {
  /*background: var(--folderNamesBackgroundHover);*/
  text-decoration: underline;
  cursor: pointer;
  /*color: var(--folderNamesColorHover);*/
}
