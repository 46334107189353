.details_share {
  display: flex;
  padding-top: 35px;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
}
.details_share a {
  text-decoration: none;
  color: var(--socialMediaIconColor);
}
.details_share a:hover {
  color: var(--socialMediaIconColorHover);
}

.shareBTN {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
  color: var(--socialMediaIconColor);
}

.shareBTN:hover {
  color: var(--socialMediaIconColorHover);
}