.packageComponent {
  width: 28%;
  min-width: 250px;
  border: 2px solid var(--brand);
  border-radius: 4px;
  margin: 0px 2% 2%;
  background-color: var(--silver);
  height: 500px;
  padding: 20px;
  position: relative;
}

.packageTitle {
  color: var(--brand);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.packagePrice {
  color: var(--brand);
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 20px;
  padding: 35px 0;
  word-wrap: break-word;
}

.packageDescription {
  text-align: center;
  font-size: 1rem;
  color: var(--mainBlack);
}

.packageBuyButtonContainer {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 35px;
  left: 0;
}

.packageBuyButton {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.packageBuyButton:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.packageBoughtButton {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  display: inline-block;
}
