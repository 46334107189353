.packagesView {
}

.packages {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.packageHelp {
  width: 80%;
  margin: 30px auto;
}

.packageHelpTitle {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.packageHelpText {
  color: var(--mainBlack);
  width: 80%;
  margin: 10px auto;
  text-align: left;
}
