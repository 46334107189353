.tosContainer {
  margin: 4% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--black);
}

.padded {
  padding-left: 1%;
}

.tosTitle, .tosMainTitle {
  font-weight: bold;
}
