.ProductDetailsContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: 2% auto;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .ProductDetailsContainer {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
    margin: 5% auto;
    flex-grow: 1;
  }

  .ProductDetailsLeft, .ProductDetailsRight {
    width: 100% !important;
  }

  .title {
    margin: 2% 0;
  }
}

.ProductDetailsLeft {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.ProductDetailsRight {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.buyButton {
  margin: 1% 0;
  color: var(--silver);
  background-color:  var(--brand);
  width: 50%;
}

.buyButton:hover {
  background-color:  var(--brandHover);
}

.image {
  width: 100%;
  height: auto;
}

.productDescription {
  margin: 2% 0 0 0;
  line-height: 16px;
}

.priceContainer {
  margin: 1% 0;
  display: flex;
  flex-direction: row;
}

.price, .vat {
  
  display: flex;
  align-items: flex-end;
}

.price {
  font-weight: bold;
  margin-right: 5px;
}

.vat {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}


.title {
  font-weight: bold;
}