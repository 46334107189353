.stateViewContainer {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.stateView {
  border-bottom: 2px solid var(--mainBlack);
  color: var(--mainBlack);
  text-align: center;
  width: 33.33%;
  font-size: 1.5rem;
}

.stateView:hover {
  cursor: pointer;
}

.stateViewSelected {
  border-bottom: 2px solid var(--brand);
  color: var(--brand);
}
