.signUpBackground {
  background: rgb(38, 113, 254);
  background: linear-gradient(
    90deg,
    rgba(134, 199, 41, 1) 0%,
    rgba(83, 140, 42, 1) 35%,
    rgba(134, 199, 41, 1) 100%
  );

  height: 240px;
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-evenly;

  align-items: center;
  flex-direction: column;
  border-radius: var(--font32);
}

.signUpBarContainer {
  display: flex;
  justify-content: center;
}

.signUpBar_description {
  font-weight: 500;
  line-height: 140%;
  color: var(--silver);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
}
.signUpBar_description > * + * {
  margin-top: 25px;
}

.signUpWelcomeText {
}
.signUpWelcomeText2 {
  font-weight: 400;
}
.signUpBarBTN {
  background: var(--silver);
  border-radius: var(--font16);
  line-height: 130%;
  color: var(--brand);
}
.signUpBarBTN:hover {
  background: var(--blueSilver);
  color: var(--brand);
}

/* media Queeries */
@media (max-width: 600px) {
  .signUpBackground {
    height: 180px;
  }
}
