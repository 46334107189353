.loggedInContainer {
  display: flex;
  flex-direction: column;
  /*padding: 50px 0 20px;*/
  width: 100%;
  align-items: center;
}

.loggedInMessage {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin: 5% 5%;
  border: 2px solid whitesmoke;
  padding: 20px;
  border-radius: 0.5rem;
  height: auto;
}

.loggedInEmail {
  color: var(--brand);
  font-style: italic;
}
