.BuyTotoContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  flex-wrap: wrap;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 350px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
  border-radius: 12px;
  flex-grow: 1;
}

.item {
  display: flex;
  flex-direction: column;
  width: 500px !important;
  margin: 0 2% 0 0;
}

.monthly {
  margin-right: 5px;
}

.itemBottomContainer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.title {
  margin: 1% 0;
  font-weight: bold;
}

.text {
  display: flex;
}

.buyButton {
  margin: 1% 0;
  color: var(--silver);
  background-color:  var(--brand);
  width: 50%;
}

.buyButton:hover {
  background-color:  var(--brandHover);
}

.priceContainer {
  margin: 1% 0;
  display: flex;
  flex-direction: row;
}

.price, .vat {
  display: flex;
  align-items: flex-end;
}

.price {
  font-weight: bold;
  margin-right: 5px;
}

.vat {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}
